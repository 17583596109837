import { db } from '@/main'
import { Observable } from 'rxjs'

export default {
  ownerID: '',
  podID: '',

  addThread (data) {
    const ref = `${this.ownerID}/podcast/${this.podID}`
    console.log(`${this.ownerID}/podcast/${this.podID}`, data)
    var newThreadKey = db
      .ref(ref)
      .child('threads')
      .push().key
    data.id = newThreadKey
    var updates = {}
    updates['threads/' + newThreadKey] = {
      ...data
    }
    return Observable.create(observer => {
      db.ref(ref)
        .update(updates)
        .then(
          () => {
            observer.next({
              id: newThreadKey
            })
          },
          err => {
            observer.error(err)
          }
        )
    })
  },

  addComment (threadId, data) {
    const ref = `${this.ownerID}/podcast/${this.podID}`
    return Observable.create(observer => {
      var newPostRef = db.ref(ref + '/threads/' + threadId + '/comments').push()
      data.id = newPostRef.key
      newPostRef.set(data)
      observer.next(newPostRef)
    })
  },

  fetchComments (threadId) {
    const ref = `${this.ownerID}/podcast/${this.podID}`
    // return db.collection('threads').doc(threadId).get();
    return Observable.create(observer => {
      db.ref(ref + '/threads/' + threadId + '/comments/').on('value', function (
        snapshot
      ) {
        observer.next(snapshot)
      })
    })
  },

  fetchThreads () {
    const ref = `${this.ownerID}/podcast/${this.podID}`
    return Observable.create(observer => {
      db.ref(`${ref}/threads`).on('value', function (snapshot) {
        observer.next(snapshot)
      })
    })
  },

  fetchThread (threadId) {
    console.log(threadId)
    const ref = `${this.ownerID}/podcast/${this.podID}`
    return Observable.create(observer => {
      db.ref(ref + '/threads/' + threadId).on('value', function (snapshot) {
        observer.next(snapshot)
      })
    })
  },

  updateComment (threadId, commentId, msg) {
    const ref = `${this.ownerID}/podcast/${this.podID}`
    return Observable.create(observer => {
      db.ref(ref + '/threads/' + threadId + '/comments/' + commentId).update({
        msg: msg
      })
      observer.next()
    })
  },

  resolveThread (threadId) {
    const ref = `${this.ownerID}/podcast/${this.podID}`
    return Observable.create(observer => {
      observer.next(db.ref(ref + '/threads/' + threadId).update({
        isResolved: true
      }))
    })
  },

  deleteComment (threadId, commentId) {
    const ref = `${this.ownerID}/podcast/${this.podID}`
    return Observable.create(observer => {
      db.ref(ref + '/threads/' + threadId + '/comments/' + commentId).update({
        resolved: true
      })
      observer.next()
    })
  },

  markThreadRead (threadId, data) {
    const ref = `${this.ownerID}/podcast/${this.podID}`
    return Observable.create(observer => {
      db.ref(ref + '/threads/' + threadId).update(data)
      observer.next()
    })
  }
}
