<template>
  <div
    ref="thread"
    @mouseover="commentThread.threadOnHover=true"
    @mouseleave="commentThread.threadOnHover=false"
    class="border rounded mb-2.5"
    :class="[
    !isThreadRead && commentThread.comments.length ? 'border-yellow' : (isNewThread ? 'border-transparent' : 'border-primary-300'),
    commentThread.isThreadOpen ? 'thread-open' : '',
    isAnyThreadActive && !commentThread.isThreadOpen ? 'opacity-50' : '',
    !commentThread.isThreadOpen ? 'cursor-pointer' : ''
    ]"
    @click="openThread"
    :id="commentThread.id"
  >

    <div
      class="max-h-80 px-4 pt-4 overflow-y-auto"
      v-if="allComments.length"
    >
      <div
        v-for="(comment,commentIndex) in allComments"
        :key="commentIndex"
      >
        <div class="relative">
          <div v-if="allComments[commentIndex + 1]">
            <div
              class="top-8 bottom-0 left-3.75 w-0.25 absolute"
              :style="{'background': `linear-gradient(to bottom, ${coloredContacts[comment.contactId].color}, ${coloredContacts[allComments[commentIndex + 1]['contactId']].color})`}"
            />
            <div
              class="border border-white border-dashed top-8 bottom-0 left-3.5 absolute"
            />
          </div>
          <div v-else-if="commentThread.isThreadOpen">
            <div
              class="top-8 bottom-0 left-3.75 w-0.25 absolute"
              :style="{'background': `linear-gradient(to bottom, ${coloredContacts[comment.contactId].color}, ${currentUserColor})`}"
            />
            <div
              class="border border-white border-dashed top-8 bottom-0 left-3.5 absolute"
            />
          </div>

          <div
            class="cursor-pointer text-sm text-blue bottom-1 left-10 absolute"
            v-if="commentIndex === 0 && hiddenCommentCount"
            @click="fullyOpen = true; scrollToLastComment();"
          >
            {{ hiddenCommentCount }} comments
          </div>

          <div
            class="flex right-0 absolute items-center"
            :key="commentIndex"
          >
            <div
              class="cursor-pointer text-primary mr-2"
              v-if="commentIndex === 0"
              v-tooltip.top="'Resolve'"
              @click="resolveCommentThread(commentThread.id)"
            >
              <CheckIcon />
            </div>

            <v-popover
              :key="commentIndex"
              placement="bottom-end"
              popoverClass="popover-sm"
              @apply-show="openPopup=commentIndex;"
              @hide="openPopup='';"
            >
              <MoreIcon
                class="cursor-pointer"
                :class="[openPopup === commentIndex ? 'text-blue' : 'text-primary']"
                :key="commentIndex"
              />

              <template slot="popover">
                <div class="p-1" :key="commentIndex">
                  <div
                    class="rounded cursor-pointer text-xs text-primary p-1.5 comment-option hover:bg-primary-200"
                    v-close-popover
                    @click="editComment(commentIndex)"
                  >
                    Edit
                  </div>

                  <div
                    class="rounded cursor-pointer text-xs text-primary p-1.5 comment-option hover:bg-primary-200"
                    v-close-popover
                    @click="deleteComment(commentIndex)"
                  >
                    Delete
                  </div>

                  <div
                    v-if="isThreadRead"
                    class="rounded cursor-pointer text-xs text-primary p-1.5 comment-option hover:bg-primary-200"
                    v-close-popover
                    @click="toggleReadState(true)"
                  >
                    Mark as unread
                  </div>
                  <div
                    v-else
                    class="rounded cursor-pointer text-xs text-primary p-1.5 comment-option hover:bg-primary-200"
                    v-close-popover
                    @click="toggleReadState(false)"
                  >
                    Mark as read
                  </div>
                </div>
              </template>
            </v-popover>
          </div>

          <div class="flex" :class="[commentIndex === 0 && hiddenCommentCount ? 'pb-8' : 'pb-4']">
            <div
              class="border border-yellow rounded-full flex h-8 mr-2 w-8 items-center justify-center"
              :style="{'border-color': coloredContacts[comment.contactId].color}"
            >
              <avatar
                :size="30"
                :src="coloredContacts[comment.contactId].photoURL"
                :username="coloredContacts[comment.contactId].displayName"
              />
            </div>

            <div class="flex-grow">
              <div class="text-sm text-primary">
                {{coloredContacts[comment.contactId].displayName}}
              </div>
              <div class="text-xs text-primary-500">
                {{ comment.relativeTime }}
              </div>

              <div
                class="mt-3 text-sm"
                :class="{
                  'mb-2.5 focus:ring-primary focus:border-primary block w-full py-2 px-4 border border-primary-300 rounded text-sm text-primary-800': activeEditIndex === commentIndex
                }"
                ref="editRef"
                @input="editText($event)"
                v-html="parseURLs(comment.msg)"
                :contenteditable="activeEditIndex === commentIndex"
                @keydown.meta.enter.exact="saveEdit(commentIndex)"
              />
              <div
                class="action-container"
                v-if="currentUser.uid === coloredContacts[comment.contactId].uid"
              >
                <div
                  class="flex items-center justify-end"
                  v-if="activeEditIndex === commentIndex"
                >
                  <Button
                    size="small"
                    variant="secondary"
                    @click="cancelEdit(commentIndex)"
                  >
                    Cancel
                  </Button>
                  <Button
                    class="ml-2.5"
                    size="small"
                    variant="primary"
                    @click="saveEdit(commentIndex)"
                  >
                    Update
                  </Button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div ref="commentListEnd" />
    </div>

    <!-- New comment / reply -->

    <div class="flex px-4 pt-4" v-show="isNewThread">
       <div
          class="border border-yellow rounded-full flex h-8 mr-2 w-8 items-center justify-center"
          :style="{'border-color': coloredContacts[currentUser.uid].color}"
        >
          <avatar
            :size="30"
            :src="currentUser.photoURL"
            :username="currentUser.displayName"
          />
        </div>

        <div class="">
          <div class="text-sm text-primary">
            {{currentUser.displayName}}
          </div>
          <div class="text-xs text-primary-500">
            {{ moment().format('hh:mm a') }}
          </div>
        </div>
    </div>
    <div
      class="px-4 pb-3"
      v-if="commentThread.isThreadOpen"
    >
      <div class="mt-3 relative">
        <!-- eslint-disable-next-line vue/valid-v-model -->
        <input
          v-model="reply"
          ref="replyInputRef"
          @input="replyOnComment($event)"
          @keyup.enter="onPostComment(commentThread)"
          :placeholder="[commentThread.comments && commentThread.comments.length ? 'Add a reply':'Add New comment']"
          class="border rounded border-primary-300 text-sm w-full py-2.25 pr-2 placeholder-primary-600 text-primary-800 replyInput focus:ring-primary focus:border-primary block"
          :class="[isNewThread ? 'pl-4' : 'pl-10']"
        />
        <div
          v-show="!isNewThread"
          class="border border-yellow rounded-full flex h-6 top-2 left-2 w-6 items-center justify-center absolute"
          :style="{'border-color': currentUserColor}"
        >
          <avatar :size="22" :src="currentUser.photoURL" :username="currentUser.displayName"/>
        </div>
      </div>
      <div v-if="commentThread.activeReplySection" class="flex mt-2.5 justify-end">
        <Button size="small" variant="secondary" :onClick="discardComment" classname="mr-2.5">
          Cancel
        </Button>
        <div @click="onPostComment(commentThread)">
          <Button size="small" :variant="commentThread.activeReplySection ? 'primary' : 'disabled'">
            Post
          </Button>
        </div>
      </div>
    </div>

    <div ref="activeThread" />
  </div>
</template>

<script>
import moment from 'moment'
import Avatar from 'vue-avatar'
import DbService from './services/DbService'
import ThreadsService from './services/ThreadsService'
import Button from '@/components/base/buttons/Button.vue'
import MoreIcon from '@/components/base/icons/More.vue'
import CheckIcon from '@/components/base/icons/Check.vue'

const contactColors = [
  '#000228',
  '#E2462C',
  '#20557D',
  '#52AADB',
  '#F8C450',
  '#EC5B16',
  '#F20D70',
  '#B4C236',
  '#53B745',
  '#8338EC',
  '#AE33D9',
  '#233AC9',
  '#0075FF'
]

export default {
  name: 'Thread',
  components: {
    Avatar,
    MoreIcon,
    CheckIcon,
    Button
  },
  props: {
    allThreads: {
      type: Array
    },
    commentThread: {
      type: Object,
      default () {
        return {}
      }
    },
    currentUser: {},
    contact: {},
    isFocussed: {
      type: Boolean,
      default: false
    },
    currentContactId: {
      type: String,
      default: ''
    },
    resetThreadState: {
      type: Function,
      default: () => null
    }
  },
  watch: {
    commentThread: function(val, oldVal) {
      if (!val.isThreadOpen && oldVal.isThreadOpen) {
        this.fullyOpen = false
        console.log('Close thread')
      }
    }
  },
  data () {
    return {
      openPopup: '',
      fullyOpen: false,
      coloredContacts: {},
      reply: '',
      activeEditIndex: -1,
      activeEditText: '',
      timeUpdateInterval: null,
      moment
    }
  },
  created () {
    let moddedContcts = {}
    Object.keys(this.contact).forEach((el, i) => {
      moddedContcts[el] = {
        ...this.contact[el],
        color: contactColors[i]
      }
    })
    this.coloredContacts = moddedContcts

    this.updateTimeForThread()
    this.timeUpdateInterval = setInterval(() => {
      this.updateTimeForThread()
    }, 10000)
    setTimeout(() => {
      if (this.commentThread.activeReplySection) {
        this.$refs.replyInputRef.focus()
      }
    }, 1000)
    setTimeout(() => {
      if (!this.commentThread.comments.length && this.$refs['replyInputRef']) {
        this.$nextTick(() => {
          this.$refs['replyInputRef'].focus()
        })
      }
    }, 1000)
  },
  destroyed () {
    if (this.timeUpdateInterval) {
      clearInterval(this.timeUpdateInterval)
    }
  },
  updated () {
    setTimeout(() => {
      if (!this.commentThread.comments.length && this.$refs['replyInputRef']) {
        this.$nextTick(() => {
          this.$refs['replyInputRef'].focus()
        })
      }
    }, 200)
  },
  computed: {
    isNewThread: function() {
      return this.commentThread.comments.length === 0
    },
    isThreadRead: function () {
      return this.commentThread.isThreadReadBy.includes(this.currentContactId)
    },
    hiddenCommentCount: function () {
      return this.commentThread.comments.length > 2 && (!this.commentThread.isThreadOpen || !this.fullyOpen) ? this.commentThread.comments.length - 2 : 0
    },
    allComments: function() {
      const allComments = this.commentThread.comments.length > 2 && (!this.commentThread.isThreadOpen || !this.fullyOpen) ? [
        this.commentThread.comments[0],
        ...this.commentThread.comments.slice(-1)
      ] : this.commentThread.comments
      console.log(allComments)
      return allComments
    },
    currentUserColor: function() {
      return this.coloredContacts[this.currentUser.uid] ? this.coloredContacts[this.currentUser.uid].color : '#F8C450'
    },
    isAnyThreadActive: function() {
      return this.allThreads.some(t => t.isThreadOpen)
    }
  },
  methods: {
    openThread () {
      if (!this.commentThread.isThreadOpen) {
        this.commentThread.isThreadOpen = true
        this.toggleReadState(false)
        this.$emit('openThread', {
          id: this.commentThread.id
        })

        if (this.commentThread.comments.length > 1) this.scrollToBottom()
        setTimeout(() => {
          if (this.$refs.replyInputRef) this.$refs.replyInputRef.focus()
        }, 200)
      }
    },

    /**
     * Update timestamps for threads
     */
    updateTimeForThread () {
      this.commentThread.comments.forEach(comment => {
        comment.relativeTime = ThreadsService.formatTime(
          new Date().getTime(),
          comment.timestamp
        )
      })
    },

    toggleReadState (flag) {
      if (flag) {
        this.commentThread.isThreadReadBy = this.commentThread.isThreadReadBy.filter(
          contactId => {
            return contactId !== this.currentContactId
          }
        )
      } else {
        if (!this.isThreadRead) {
          this.commentThread.isThreadReadBy.push(this.currentContactId)
        }
      }
      console.log(this.commentThread.isThreadReadBy)
      DbService.markThreadRead(this.commentThread.id, {
        isThreadReadBy: this.commentThread.isThreadReadBy
      }).subscribe(res => {
        console.log(res)
      })
    },

    scrollToBottom () {
      if (this.commentThread.comments && this.commentThread.comments.length) {
        this.$nextTick(() => {
          let container = this.$refs['activeThread']
          container.scrollTop = container.scrollHeight
        })
      }
    },

    scrollToLastComment () {
      if (this.allComments.length) {
        setTimeout(() => {
          this.$refs.commentListEnd.scrollIntoView()
        }, 200)
      }
    },

    editComment (index) {
      this.activeEditText = this.commentThread.comments[index].msg
      this.commentThread.comments[index].isEditSectionOpen = true
      this.activeEditIndex = index

      setTimeout(() => {
        let el = this.$refs.editRef[index]
        let range = document.createRange()
        let sel = window.getSelection()
        range.setStart(el.childNodes[0], this.activeEditText.length)
        range.collapse(true)
        sel.removeAllRanges()
        sel.addRange(range)
        el.focus()
      }, 1000)
    },
    replyOnComment (event) {
      if (event.target && event.target.value) {
        this.reply = event.target.value
        this.reply ? this.$emit('isTyping', true) : this.$emit('isTyping', false)
        if (this.reply) {
          this.commentThread.activeReplySection = true
        } else {
          this.commentThread.activeReplySection = false
        }
      }
    },
    editText (event) {
      if (event.target && event.target.innerText && event.target.innerText.length <= 100) {
        this.activeEditText = event.target && event.target.innerText
      }
    },
    onPostComment () {
      let msg = this.reply.trim()
      if (!msg) return
      this.$emit('postThread', {
        id: this.commentThread.id,
        message: msg
      })
      this.$refs.replyInputRef.innerHTML = ''
      this.reply = ''
      this.commentThread.activeReplySection = false
    },

    discardComment () {
      // close popover if new comment
      if (this.allComments.length === 0) return this.resetThreadState()
      this.reply = ''
      this.$refs.replyInputRef.innerHTML = ''
      this.commentThread.activeReplySection = false
      this.$emit('discardComment')
    },

    saveEdit (commentIndex) {
      this.activeEditText = this.activeEditText.trim()
      if (this.activeEditText && this.activeEditText.length) {
        this.commentThread.comments[commentIndex].msg = this.activeEditText
        this.commentThread.comments[commentIndex].isEditSectionOpen = false
        this.activeEditIndex = -1
        this.activeEditText = ''
        DbService.updateComment(
          this.commentThread.id,
          this.commentThread.comments[commentIndex].id || 0,
          this.commentThread.comments[commentIndex].msg
        ).subscribe(res => {
          console.log(res)
        })
      }
    },

    cancelEdit (commentIndex) {
      this.reply = ''
      this.$refs.replyInputRef.innerHTML = ''
      this.$refs.replyInputRef.focus()
      this.commentThread.comments[commentIndex].isEditSectionOpen = false
      this.activeEditIndex = -1
      this.$refs.editRef[commentIndex].innerHTML = this.commentThread.comments[commentIndex].msg
    },

    deleteComment (commentIndex) {
      DbService.deleteComment(
        this.commentThread.id,
        this.commentThread.comments[commentIndex].id || 0
      ).subscribe(res => {
        console.log(res)
        this.commentThread.comments[commentIndex].resolved = true
        if (this.commentThread.comments.length === 1) {
          this.$emit('deleteThread', this.commentThread.id)
        }
      })
    },
    resolveCommentThread (commentId) {
      this.$emit('deleteThread', commentId)
    },

    parseURLs (text) {
      let newText = text.split(' ')
      newText = newText.map(str => {
        let a = document.createElement('a')
        a.href = str
        if (a.host && a.host !== window.location.host) {
          // return '<a>sda  </a>'
          return `<a href="${str}" target="_blank" rel="noopener">${str}</a>`
        } else {
          return str
        }
      })
      text = newText.join(' ')
      return text
    }
  }
}
</script>

<style lang="scss" scoped>
.thread-open {
  box-shadow: 0px 0px 6px rgba(0, 2, 40, 0.1);
}

.thread-unread {
  border: 1px solid #F8C450;
}

.replyInput {
  outline: none;
  cursor: text;
  color: #000228;
}
</style>
