<template>
  <div v-if="unresolvedComments.length" class="comment-section">
    <div
      :ref="'commentThread'+commentIndex"
      class="comment-box"
      v-for="(commentItem, commentIndex) in unresolvedComments"
      :class="{'active-comment': commentItem.showInput}"
      :key="commentIndex"
      @mouseover="emitHighlightEvent(commentIndex, true)"
      @mouseleave="emitHighlightEvent(commentIndex, false)"
    >
      <Thread
        :allThreads="unresolvedComments"
        :isFocussed="currentFocussedThreadIndex === commentIndex"
        :currentUser="contacts[currentUser]"
        :currentContactId="currentUser"
        :contact="contacts"
        :commentThread="commentItem"
        ref="comment"
        @openThread="openThread($event, commentIndex)"
        @deleteThread="deleteCommentThread(commentIndex)"
        @updateTops="updateThreadPositions()"
        @postThread="postThread($event, commentIndex)"
        @isTyping="typingInThread($event,commentIndex)"
        @discardComment="discardComment"
        :resetThreadState="resetThreadState2"
      />
    </div>
  </div>
  <div v-else class="flex flex-col mt-32 text-primary-500 comment-section items-center">
    <div>
      <ChatIcon />
    </div>
    <div class="text-sm text-center leading-6 w-56">
      All clear! <br/>
      No comments to show 😶
    </div>
  </div>
</template>

<script>
import ChatIcon from '@/components/base/icons/Chat.vue'
import Thread from './Thread.vue'
import DbService from './services/DbService'
import ThreadsService from './services/ThreadsService'

export default {
  name: 'ThreadsContainer',
  props: {
    commentThreadsAndPositions: {},
    contacts: {},
    currentUser: {
      type: String,
      default: ''
    },
    forceShowComments: {
      type: Function,
      default: () => null
    }
  },
  components: {
    ChatIcon,
    Thread
  },
  data () {
    return {
      showInput: false,
      inputTop: 0,
      inputValue: '',
      showInputButtons: false,
      isBottomForInputAdjusted: false,
      indexToInsertAt: -1,
      newThread: {},
      commentsList: [],
      currentOpenThreadIndex: -1,
      currentOpenThreadId: -1,
      timeUpdateInterval: null,
      previousTopState: [],
      isNewThread: false,
      currentFocussedThreadIndex: -1,
      rawThreads: {},
      isTyping: false,
      currentMouseOverComment: -1,
      newCommentPost: false,
      newCommendId: ''
    }
  },
  computed: {
    unresolvedComments: function() {
      const unresolvedThreads = this.commentsList.filter(t => !t.isResolved && (t.comments.length || t.isThreadOpen))
      return unresolvedThreads
    }
  },
  created: function () {
    let vm = this
    DbService.ownerID = vm.$route.params.userId
    DbService.podID = vm.$route.params.podId
    window.addEventListener('click', this.documentClick)
    window.addEventListener('mouseover', this.documentMouseOver)
    this.initComments()
  },
  watch: {
    currentMouseOverComment (newVal, oldVal) {
      if (oldVal !== -1 && newVal !== -1) {
        let el = this.getElementsByAttribute('data-cid', newVal)
        if (el.length > 0) {
          el[0].classList.add('mouseover')
        }
        el = this.getElementsByAttribute('data-cid', oldVal)
        if (el.length > 0) {
          setTimeout(function () {
            el[0].classList.remove('mouseover')
          }, 200)
        }
        return
      }
      if (newVal !== -1) {
        let el = this.getElementsByAttribute('data-cid', newVal)
        if (el.length > 0) {
          el[0].classList.add('mouseover')
        }
      }
      if (oldVal !== -1) {
        let el = this.getElementsByAttribute('data-cid', oldVal)
        if (el.length > 0) {
          el[0].classList.remove('mouseover')
        }
      }
    },
    currentOpenThreadId (newVal, oldVal) {
      if (oldVal !== -1 && newVal !== -1) {
        let el = this.getElementsByAttribute('data-cid', newVal)
        if (el.length > 0) {
          el[0].classList.add('active')
        }
        el = this.getElementsByAttribute('data-cid', oldVal)
        if (el.length > 0) {
          setTimeout(function () {
            el[0].classList.remove('active')
          }, 200)
        }
        return
      }
      if (newVal !== -1) {
        let el = this.getElementsByAttribute('data-cid', newVal)
        if (el.length > 0) {
          el[0].classList.add('active')
        }
      }
      if (oldVal !== -1) {
        let el = this.getElementsByAttribute('data-cid', oldVal)
        if (el.length > 0) {
          el[0].classList.remove('active')
        }
      }
    },
    commentThreadsAndPositions: {
      handler: function (newVal, oldVal) {
        let threadDifference = Object.keys(newVal).filter(
          k => oldVal[k] !== newVal[k]
        )
        if (threadDifference.length > 0) {
          this.parseThreads()
        }
      }
    },
    rawThreads: function () {
      console.log('rawThreads changed')
      this.parseThreads()
    }
  },
  mounted: function () {
    this.updateTops()
    this.checkForThreadOverlap()
  },
  methods: {
    createNewThread (data) {
      this.isNewThread = true
      // this.$set(this.commentThreadsAndPositions, data)
      // console.log(this.commentThreadsAndPositions);
      const key = Object.keys(data)[0]
      this.newCommendId = key

      if (this.commentsList.length) {
        this.removeEmptyThread()
        this.resetThreadState()
      }

      this.createThread(
        key,
        data[key],
        true,
        null,
        null,
        false,
        false,
        true,
        []
      )
      this.updateTops()
      this.sortThreadsForTop()
      const threadIndex = this.commentsList.findIndex(thread => {
        return thread.id === key
      })
      this.currentOpenThreadIndex = threadIndex
      this.updateTopsBefore(threadIndex)
      this.updateTopsAfter(threadIndex)
    },

    updateTopsBefore (threadIndex) {
      this.forceShowComments()

      this.$nextTick(() => {
        if (threadIndex > 0) {
          for (let i = threadIndex; i > 0; i--) {
            if (this.commentsList[i] && this.commentsList[i - 1].end >= this.commentsList[i].top) {
              const factor =
                this.commentsList[i - 1].end - this.commentsList[i].top + 30
              // console.log(factor)

              this.commentsList[i - 1].top -= factor
              this.commentsList[i - 1].end -= factor
            }
          }
        }
      })
    },

    updateTopsAfter (threadIndex) {
      console.log('updateTopsAfter', threadIndex)
      this.forceShowComments()

      this.$nextTick(() => {
        if (threadIndex < this.commentsList.length) {
          for (let i = threadIndex; i < this.commentsList.length - 1; i++) {
            if (this.commentsList[i + 1].top < this.commentsList[i].end) {
              const factor =
                this.commentsList[i].end - this.commentsList[i + 1].top + 30
              // console.log(factor)

              this.commentsList[i + 1].top += factor
              this.commentsList[i + 1].end += factor
            }
          }
        }
      })
    },

    removeEmptyThread () {
      this.commentsList = this.commentsList.filter(thread => {
        return thread.comments && thread.comments.length > 0
      })
    },

    /**
     * Initialize Threads as per the input received
     */
    initComments () {
      this.$nextTick(() => {
        DbService.fetchThreads().subscribe(snapshot => {
          const data = snapshot.val()
          if (data) {
            this.rawThreads = data
          }
        })
      })
    },
    checkForActiveThread () {
      let vm = this
      return this.commentsList.find(thread => {
        return thread.id === vm.newCommendId
      })
    },

    parseThreads () {
      if (!this.newCommentPost) {
        const activeThread = this.checkForActiveThread()
        // console.log('activeThread', activeThread)
        this.commentsList = []
        if (activeThread) {
          this.commentsList.push(activeThread)
        }
        Object.keys(this.commentThreadsAndPositions).forEach(id => {
          if (this.rawThreads[id]) {
            this.pushThreadIntoList(id)
          }
        })
      } else {
        Object.keys(this.commentThreadsAndPositions).forEach(id => {
          if (!this.isThreadPresent(id)) {
            this.pushThreadIntoList(id)
          }
        })
        this.newCommentPost = false
      }
      this.sortThreadsForTop()
      this.updateTops()
      if (this.currentOpenThreadIndex !== -1) {
        this.updateTopsBefore(this.currentOpenThreadIndex)
        this.updateTopsAfter(this.currentOpenThreadIndex)
      }
      this.checkForThreadOverlap()
    },

    isThreadPresent (id) {
      return this.commentsList.find(thread => {
        return thread.id === id
      })
    },

    pushThreadIntoList (id) {
      const threadData = this.rawThreads[id]
      if (!threadData) {
        return
      }
      threadData.commentsList = []
      Object.keys(threadData.comments).forEach(key => {
        if (!threadData.comments[key].resolved) {
          threadData.commentsList.push(threadData.comments[key])
        }
      })
      if (threadData.commentsList && threadData.commentsList.length) {
        const isThreadOpen = this.currentOpenThreadId === id
        this.createThread(
          id,
          this.commentThreadsAndPositions[id],
          false,
          threadData.height,
          threadData.commentsList,
          true,
          threadData.isResolved,
          isThreadOpen,
          threadData.isThreadReadBy
        )
      }
    },

    fetchThread (threadKey) {
      DbService.fetchThread(threadKey).subscribe(snapshot => {
        const data = snapshot.val()
        console.log(data)
        if (data) {
          data.commentsList = []
          Object.keys(data.comments).forEach(key => {
            if (!data.comments[key].resolved) {
              data.commentsList.push(data.comments[key])
            }
          })
          data.top = this.commentThreadsAndPositions[threadKey]
          this.createThread(
            data.id,
            this.commentThreadsAndPositions[threadKey],
            false,
            data.height,
            data.commentsList,
            true,
            data.isResolved,
            false,
            data.isThreadRead
          )
          this.sortThreadsForTop()
          this.updateTops()
          this.checkForThreadOverlap()
        }
      })
    },

    updateTopForThread (id) {
      const threadIndex = this.commentsList.findIndex(thread => {
        return thread.id === id
      })
      if (threadIndex !== -1) {
        this.commentsList[threadIndex].top = this.commentThreadsAndPositions[id]
        this.commentsList[threadIndex].previousTop = this.commentThreadsAndPositions[id]
        this.sortThreadsForTop()
        this.updateTops()
        this.checkForThreadOverlap()
      }
    },

    /**
     * Update tops, height and end propperties of a .thread
     * To be called after any update to the thread. Additio, deletion of new thread or comment
     */
    updateTops () {
      this.$nextTick(() => {
        for (
          let threadIndex = 0;
          threadIndex < this.commentsList.length;
          threadIndex++
        ) {
          if (
            this.commentsList &&
            this.commentsList[threadIndex] &&
            !this.commentsList[threadIndex].isResolved &&
            this.$refs['commentThread' + threadIndex][0]) {
            this.commentsList[threadIndex].effectiveHeight = this.$refs['commentThread' + threadIndex][0].clientHeight
            this.commentsList[threadIndex].end =
              this.commentsList[threadIndex].top +
              this.commentsList[threadIndex].effectiveHeight
          } else {
            if (this.commentsList && this.commentsList[threadIndex]) {
              this.commentsList[threadIndex].top = 0
              this.commentsList[threadIndex].previousTop = 0
              this.commentsList[threadIndex].effectiveHeight = 0
              this.commentsList[threadIndex].end = 0
            }
          }
        }
      })
    },

    /**
     * Sort threads according to their tops. To be called after addition, deletion of new threads
     */
    sortThreadsForTop () {
      this.commentsList = this.commentsList.sort((a, b) => a.top - b.top)
    },

    /**
     * Check if current thread overlaps with previous one.
     * Update its top with an appropriate padding.
     */
    checkForThreadOverlap () {
      this.$nextTick(() => {
        for (let i = 1; i < this.commentsList.length; i++) {
          if (this.commentsList[i - 1].end >= this.commentsList[i].top) {
            const factor =
              this.commentsList[i - 1].end - this.commentsList[i].top + 30
            this.commentsList[i].top += factor
            this.commentsList[i].end += factor
          }
        }
        this.updateTops()
      })
    },

    /**
     * Create a new thread object with default properties
     */
    createThread (
      threadId,
      threadTop,
      showInput,
      height,
      comments,
      updateComment,
      isResolved,
      isThreadOpen,
      isThreadReadBy
    ) {
      const index = this.commentsList.findIndex(comment => {
        return comment.id === threadId
      })
      if (comments) {
        comments.forEach(comment => {
          comment.relativeTime = ThreadsService.formatTime(
            new Date().getTime(),
            comment.timestamp
          )
        })
      }
      if (index === -1) {
        this.commentsList.push({
          id: threadId,
          top: threadTop,
          previousTop: threadTop,
          comments: showInput ? [] : comments,
          isResolved: isResolved,
          isThreadReadBy: isThreadReadBy || [],
          isThreadOpen: isThreadOpen,
          threadOnHover: false,
          showInput: showInput,
          effectiveHeight: height || 80,
          end: height ? threadTop + height : threadTop + 80
        })
      } else {
        if (updateComment) {
          this.commentsList[index].comments = comments
          this.commentsList[index].isResolved = isResolved
          this.commentsList[index].isThreadOpen = isThreadOpen
          this.commentsList[index].isThreadReadBy = isThreadReadBy
          this.commentsList[index].top = threadTop
        }
      }
    },

    /**
     * Fetch comments for a thread acording to its id
     */
    getCommentsForThread (threadId) {
      // const contactId = 'tyuiop'

      this.$nextTick(() => {
        DbService.fetchComments(threadId).then(docRef => {
          if (docRef.exists) {
            const index = this.commentsList.findIndex(thread => {
              return thread.id === threadId
            })
            this.commentsList[index].comments = docRef.data().comments
            this.commentsList[index].comments.forEach(comment => {
              comment.relativeTime = ThreadsService.formatTime(
                new Date().getTime(),
                comment.timestamp
              )
            })

            this.updateThreadPositions()
          } else {
            return []
          }
        })
      })
    },

    /**
     * Fetch user details for a comment with its id
     */
    getContact (contactId) {
      return this.contacts[contactId]
    },

    /**
     * Open thread and update tops
     */
    openThread (event, threadIndex) {
      console.log('openThread', event, threadIndex)
      if (this.commentsList[threadIndex].comments.length !== 0) {
        this.resetThreadState()
        this.currentOpenThreadId = this.commentsList[threadIndex].id
        // logic to scroll active comment into view
        const el = document.querySelector(`[data-cid=${this.currentOpenThreadId}]`)
        if (el) el.scrollIntoView()

        this.currentOpenThreadIndex = threadIndex
        this.commentsList[threadIndex].isThreadOpen = true
        // this.sortThreadsForTop();
        this.updateTops()
        this.checkForThreadOverlap()

        // this.updateTimeForThreads(index);
        this.storePreviousTopState()
      }
    },

    openThreadFromCommentMark (event) {
      const threadIndex = this.commentsList.findIndex(thread => {
        return thread.id === event.id
      })
      if (this.commentsList[threadIndex].comments.length !== 0) {
        this.resetThreadState()
        this.currentOpenThreadId = this.commentsList[threadIndex].id
        this.currentOpenThreadIndex = threadIndex
        this.commentsList[threadIndex].isThreadOpen = true
        // this.sortThreadsForTop();
        this.updateTops()
        this.checkForThreadOverlap()

        // this.updateTimeForThreads(index);
        this.storePreviousTopState()
      }
    },

    storePreviousTopState () {
      this.commentsList.forEach(thread => {
        this.previousTopState.push(thread.top)
      })
      // console.log(this.previousTopState);
    },

    resetPreviousThreadTops (threadIndex) {
      if (threadIndex === this.currentOpenThreadIndex) {
        this.commentsList.forEach((thread, index) => {
          thread.top = this.previousTopState[index]
        })
        this.updateTops()
        this.checkForThreadOverlap()
        this.currentOpenThreadIndex = -1
        this.previousTopState = []
      }
    },

    updateThreadPositions () {
      this.updateTops()
      this.checkForThreadOverlap()
    },

    /**
     *  Reset thread state and restore previous tops
     */
    resetThreadState () {
      this.commentsList.forEach(thread => {
        thread.isThreadOpen = false
        thread.top = thread.previousTop
      })
    },

    resetThreadState2 () {
      this.commentsList.forEach(thread => {
        thread.isThreadOpen = false
        thread.top = thread.previousTop
      })
      this.$emit('reset')
    },

    deleteCommentThread (commentThreadId) {
      let vm = this
      this.commentsList[commentThreadId].isResolved = true
      let threadId = this.commentsList[commentThreadId].id
      DbService.resolveThread(threadId).subscribe(
        res => {
          res.then(function () {
            vm.$emit('deleteThread', threadId)
          }).catch(function (e) {
            console.log(e)
          })
        }
      )
      this.updateTops()
    },

    removeEmptyComment () {
      this.commentsList = this.commentsList.filter(comment => {
        return comment.comments.length !== 0
      })
    },

    postThread (event, commentIndex) {
      console.log('posThread', event, commentIndex)
      let vm = this
      const newComment = this.createComment(event.message)

      const currentOpenThread = this.commentsList[commentIndex]
      console.log('Openthread', currentOpenThread)

      currentOpenThread.isThreadOpen = true
      currentOpenThread.comments.push(newComment)
      currentOpenThread.isThreadReadBy.push(this.currentUser)
      this.currentOpenThreadIndex = commentIndex

      // create new thread
      if (this.commentsList[commentIndex].comments.length === 1) {
        DbService.addThread(this.commentsList[commentIndex]).subscribe(
          data => {
            vm.isTyping = false
            vm.currentOpenThreadId = data.id
            vm.newCommentPost = true
            vm.$emit('newThread', data.id)
          },
          error => {
            console.log(error)
          }
        )
      } else {
        // create new comment
        this.postComment(
          commentIndex,
          this.commentsList[commentIndex].id,
          newComment
        )
      }
    },

    postComment (commentIndex, id, newComment) {
      DbService.addComment(id, newComment).subscribe(data => {
        console.log(data)
        this.isTyping = false
        this.commentsList[commentIndex].comments[this.commentsList[commentIndex].comments.length - 1].id = data.key
      })
    },

    createComment (message) {
      const contactId = this.currentUser
      return {
        msg: message,
        uid: '',
        timestamp: new Date().getTime(),
        contactId: contactId,
        markedAsRead: false,
        resolved: false
      }
    },
    scrollToActiveThread (data) {
      this.resetThreadState()
      const threadIndex = this.commentsList.findIndex(thread => {
        return thread.id === data.id
      })
      if (threadIndex !== -1) {
        setTimeout(() => {
          document.getElementById(data.id).scrollIntoView()
        }, 100)
        const factor = this.commentsList[threadIndex].top - data.top
        this.commentsList.forEach(thread => {
          thread.top -= factor
        })
        this.commentsList[threadIndex].isThreadOpen = true
        this.currentOpenThreadId = this.commentsList[threadIndex].id
        this.currentOpenThreadIndex = threadIndex
        this.updateTops()
        this.updateTopsBefore(threadIndex)
        this.updateTopsAfter(threadIndex)
      }
    },

    /**
     * Highlight thread with border
     * data: {id: string, isFocused: boolean}
     */
    highlightThread (data) {
      if (data.isFocused) {
        const threadIndex = this.commentsList.findIndex(thread => {
          return thread.id === data.id
        })
        // console.log('threadIndex', threadIndex)
        this.currentFocussedThreadIndex = threadIndex
        // document.getElementById(data.id).scrollIntoView()
      } else {
        this.currentFocussedThreadIndex = -1
      }
    },

    documentClick (event) {
      let vm = this
      // if comment is clicked, open the thread, else resetthreadstate
      let outsideThread = true
      let onCommentMark = false
      for (let index in event.path) {
        let el = event.path[index]
        if (el.classList && el.classList.contains('comment')) {
          onCommentMark = true
          vm.scrollToActiveThread({ id: el.dataset['cid'], top: this.commentThreadsAndPositions[el.dataset['cid']] })
        }
        if (el.classList && (el.classList.contains('comment-box') || el.classList.contains('comment-option'))) outsideThread = false
      }

      if (!onCommentMark &&
          outsideThread &&
          !this.isNewThread &&
          !this.isTyping &&
          (this.currentOpenThreadIndex !== -1 || this.currentOpenThreadId !== -1)) {
        vm.resetThreadState2()
        this.currentOpenThreadIndex = -1
        this.currentOpenThreadId = -1
      }
      this.updateTops()
      this.checkForThreadOverlap()
      this.isNewThread = false
    },
    documentMouseOver (event) {
      let vm = this
      // console.log(event)
      // console.log(event.path)
      // if comment is clicked, open the thread, else resetthreadstate
      let outsideThread = true
      let onCommentMark = false
      for (let index in event.path) {
        let el = event.path[index]

        if (el.classList && el.classList.contains('comment')) {
          // console.log('el', el)
          onCommentMark = true
          // vm.scrollToActiveThread({id: el.dataset['cid'], top: this.commentThreadsAndPositions[el.dataset['cid']]})
          vm.highlightThread({ id: el.dataset['cid'], isFocused: true })
        }
        if (el.classList && el.classList.contains('comment-box')) outsideThread = false
      }
      if (!onCommentMark && outsideThread) {
        vm.highlightThread({ isFocused: false })
      }
    },
    /**
     * Emits an event when hovering on / off comment thread
     * Event Data = { id: string, value: boolean}
     */
    emitHighlightEvent (index, flag) {
      if (flag && this.currentMouseOverComment === -1) {
        this.currentMouseOverComment = this.commentsList[index].id
      } else if (!flag && this.currentMouseOverComment !== -1) {
        this.currentMouseOverComment = -1
      }
      // console.log(this.currentMouseOverComment)
    },
    typingInThread (isTyping, commentIndex) {
      this.isTyping = isTyping
    },
    getElementsByAttribute (attr, val) {
      var nodeList = document.getElementsByTagName('*')
      var nodeArray = []

      // eslint-disable-next-line no-cond-assign
      for (var i = 0, elem; elem = nodeList[i]; i++) {
        switch (typeof val) {
          case 'undefined':
            if (typeof elem.getAttribute(attr) !== 'undefined') nodeArray.push(elem)
            break
          default:
            if (elem.getAttribute(attr) === val) nodeArray.push(elem)
            break
        }
      }

      return nodeArray
    },
    discardComment () {
      this.isTyping = false
    }
  }
}
</script>

<style lang="scss" scoped>
.comment-section {
  margin-right: 12px;
  height: calc(100% - 60px);
  overflow-y: auto;
  position: relative;
  z-index: 1;
  padding: 0 12px 0 20px;
}

.comment-box {
  position: relative;
  z-index: 2;
  transition: top 0.2s ease-in-out;
}
</style>
