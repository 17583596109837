<template>
  <div class="relative block root">
    <div
      class="relative p-4 text-lg font-semibold bg-white font-h text-primary"
    >
      Comments
    </div>

    <ThreadsContainer
      :currentUser="user.uid"
      :contacts="contacts"
      :commentThreadsAndPositions="commentThreadsAndPositions"
      class="comments"
      ref="comment"
      @newThread="commentThreadCreated"
      @deleteThread="commentThreadResolved"
      @reset="reset"
      :forceShowComments="forceShowComments"
    />
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import store from '@/services/store'
import ThreadsContainer from './ThreadsContainer/ThreadsContainer.vue'
import { myProseEditor } from '@/view/voiceEditor/proseEditor/util/utility'

export default {
  name: 'SideBarComments',
  components: {
    ThreadsContainer
  },
  computed: {
    contacts() {
      if (
        store.state.editor.podcastInfo &&
        store.state.editor.podcastInfo.collaborators
      ) {
        return store.state.editor.podcastInfo.collaborators
      }
      return {}
    },
    commentThreadsAndPositions() {
      if (!store.state.comment.commentThreadsAndPositions) return {}
      return store.state.comment.commentThreadsAndPositions
    },
    newComment() {
      return store.state.comment.newComment
    },
    user() {
      return store.state.app.user
    }
  },
  methods: {
    ...mapActions({
      togglePanel: 'editor/togglePanel'
    }),
    forceShowComments: function () {
      this.togglePanel({ panel: 'comments', forceShow: true })
    },
    reset() {
      myProseEditor.resetCommentPos()
    },
    createNewThread(ctid, pos) {
      console.log('comment id, pos', ctid, pos)
      this.forceShowComments()
      myProseEditor.updateCommentPos()
      this.$refs.comment.createNewThread({ [ctid]: pos })
    },
    commentThreadCreated(id) {
      console.log('commentThreadCreated', id)
      myProseEditor.resetCommentPos()
      myProseEditor.addCommentMark(id)
    },
    commentThreadResolved(id) {
      console.log('commentThreadResolved', id)
      myProseEditor.removeCommentMark(id)
    }
  },
  watch: {
    contacts(val) {
      // TODO: something going wrong here
      // myProseEditor.updateContacts(val)
    },
    newComment: {
      handler(val) {
        if (val.id !== '') {
          this.createNewThread(val.id, val.pos)
        }
      }
    }
  },
  beforeDestroy() {
    store.commit('comment/reset')
  }
}
</script>
<style lang="scss" scoped>
.root {
  height: 100%;
}
</style>
